* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-align: justify;
}
h1,h2,h3,h4,h5,h6{
  text-align: start;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.agent .dropdown-menu {
  width: 100%;
  max-height: 550px;
  overflow-y: auto;
}

.agent #dropdown-basic-button {
  color: #000;
  background: transparent !important;
  width: 100%;
  border: none;
  text-align: start;
}

.agent .dropdown-toggle:hover {
  color: #000;
}

.agent .dropdown-toggle::after {
  display: none;
}