/* AllShowings.css */

.back-button {
    display:flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 15px;
    margin-top: 30px;
}



/* .table-container {
    margin-top: 20px;
    overflow-x: auto;
} */

/* @media screen and (max-width: 768px) {
    .container {
        padding: 10px;
    }
    
    .showings-card-header {
        flex-direction: column;
        text-align: center;
    }
    
    .back-button {
        justify-content: center;
        position: absolute;
        top: 80px;
        right: 10px;
        z-index: 1000;
    }
} */

.underlineAgent {
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-bottom: 5px;
    border-style: solid;
    padding: 2px 5px;
}
.textStart{
    text-align: start !important;
    display: inline-block;
}