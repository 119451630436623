/* SingleShowing.css */



.back-button {
    display:flex-end;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 15px;
    margin-top: 35px;
}
.smallText {
    font-size: 20px;
}
.attendees-card {
    margin: 10px 0;
    background-color: #f0f8ff; /* Light blue subtle */
    padding: 15px;
    border-radius: 8px;
}

.attendees-card-header {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    color: #007bff; /* Bootstrap primary blue */
    flex-wrap: wrap;
}

.table-container {
    margin-top: 20px;
    overflow-x: auto;
}

@media screen and (max-width: 768px) {
    .container {
        padding: 10px;
    }
    
    .attendees-card-header {
        flex-direction: column;
        text-align: center;
    }
    .attendees-card-header h5 {
        font-size: 15px;
        margin: 0;
    }
    .smallText {
        font-size: 15px;
    }
    
    .back-button {
        margin-top: 45px;
    }
}
