.button-c {
    min-width: 200px;
}

.image-container {
    min-width: 100%;
    max-width: 100%;
    min-height: 300px;
    max-height: 300px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 0 10px #00000030;
}

.image-containers {
    position: relative;
    min-width: 100%;
    max-width: 100%;
    min-height: 300px;
    max-height: 500px;
    object-fit: cover;
    box-shadow: 0 0 10px #00000030;
}

.tedxtblue {
    color: blue;
}

.seePhotoBtn {
    font-size: 18px;
    border: none;
    color: rgb(0, 110, 255);
    background: #eee;
    border-radius: 15px;
    float: right;
    text-align: center;
}

.closeBtn {
    position: absolute;
    z-index: 10;
    border: none;
    top: 0;
    right: 0;
    font-size: 25px;
    background: transparent;
}
