.background {
    background-color: #eee;
    position: relative;
    margin: 20px 50px;
    border-radius: 10px;
    box-shadow: 0 0 10px #00000030;
}

.search-button {
    position: absolute;
    bottom: 10px;
    right: 50px;
    min-width: 130px;
}

/* Style for dropdowns */
select.required-number,
select.required-number2 {
    background-color: white;
    border: 1px solid #ccc;
    color: #555;
    padding: 6px 10px;
    border-radius: 5px;
    width: 100%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

select.required-number:focus,
select.required-number2:focus {
    border-color: skyblue;
    outline: none;
    box-shadow: 0 0 6px 1px skyblue;
}


.custom-select-container .react-select__menu {
    text-align: left;
  }
  
  .custom-select-container .react-select__option {
    text-align: left;
  }
  
  .custom-select-container .react-select__control {
    text-align: left;
  }
  