.team-card {
    box-shadow: 0 0px 40px #00000035;
    border-radius: 10px;

}


.image {
    min-width: 200px;
    max-width: 200px;
    min-height: 200px;
    height: auto;
    box-shadow: 0 0px 10px #00000055;
    border-radius: 8px;
    object-fit: cover;
}

.content {
    text-align: left;
}
.team-card:hover .image {
    transform: scale(1.1);
  opacity: 0.9;
  transition: transform 0.3s ease, opacity 0.3s ease;
}
