.serviceRequest-container {
    width: 80%;
    margin: auto;
}

.service-header {
    font-size: 36px;
    font-weight: 500;
    margin-left: 30px;
}

.service-para {
    margin-top: 25px;
    font-size: 18px;
    width: 80%;
    margin-left: 30px;
    font-weight: 500;
}

.service-H_details {
    margin-top: 30px;
    font-size: 20px;
    font-weight: 500;
    margin-left: 30px;
}

.service-H_details a {
    color: #007bff;
    text-decoration: none;
}

.service-H_details a:hover {
    color: #1766bb;
    text-decoration: underline;
}

.service-form {
    margin-left: 5%;
    width: 90%;
    margin-top: 40px;
}

.input-div {
    display: grid;
    grid-template-columns: 25% auto;
    column-gap: 20px;
    padding: 15px 0px;
}

.service-form-label {
    text-align: start;
    font-size: 18px !important;
}

.service-form-label2 {
    font-size: 18px !important;
    padding-bottom: 10px;
}

.service-form-label3 {
    font-size: 28px !important;
    font-weight: 500;
}

.service-form-input {
    width: 100%;
    height: 36px;
}

.service-form-input2 {
    width: 100%;
}

.checkbox-div {
    display: flex;
    margin-top: 10px;
}

.PleaseDescribe {
    background-color: #d1ecf1;
    border: 1px solid #bee5eb;
    padding: 10px 15px;
    border-radius: 5px;
    color: #0c5460;
    font-size: 18px;
}

.service-form-para2-H {
    font-size: 28px;
    font-weight: 500;
    margin: 15px 0px;
}

.service-form-checkbox {
    margin: 0px 15px 0px 0px;
}

.service-form-para2-text {
    font-size: 16px;
    margin: 15px 0px;
}

.Upload-Image {
    width: 300px;
    height: 150px;
    display: block;
    border: 1px dashed black;
}

/* Custom File Upload Styles */
.custom-file-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;  /* Makes it invisible but still clickable */
    cursor: pointer;
}

.custom-file-label {
    border: 2px dashed rgb(189, 189, 189);
    background-color: #f8f9fa;
    color: #007BFF;
    border-radius: 5px;
    width: 100%;
    padding: 10% 0px;
    text-align: center;
    font-size: 18px;
    cursor: pointer; /* Make it clickable */
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.custom-file-label::after {
    content: '';
}

.file-upload-container:hover .custom-file-label,
.file-upload-container.drag-over .custom-file-label {
    background-color: #e0e0e0;
    border-color: #0056b3;
}

.custom-file-input:focus + .custom-file-label {
    border-color: #0056b3;
    outline: 3px solid #0056b3; /* Adds focus outline */
}

.custom-file-input::before {
    content: '';
    display: block;
    background-color: transparent;
    color: white;
    border-radius: 5px;
    padding: 5px 10px;
}

.custom-file-input:hover::before {
    background-color: #0056b3;
}

/* Button Styles */
.Property-Currently-button {
    padding: 6px 40px;
    border-radius: 4px;
    border: none;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.Property-Currently-button:hover {
    color: #fff;
    background-color: #0361c5;
    transform: scale(1.05); /* Slightly enlarge the button on hover */
}

.Property-Currently-button:focus {
    outline: 3px solid #0056b3;
}

/* Additional Spacing for Toggle and Checkbox */
.doYouNeed2 {
    margin-top: -7px;
}

.serviceCall_check {
    margin-right: 10px;
    margin-top: 1px;
}

.FileUploadHeader {
    font-weight: 600;
    font-size: 20px;
    margin: 10px 0px -15px 0px;
}

.lineWave_main {
    display: grid;
    place-items: center;
    width: 100%;
    height: 100vh;
}

.w100Icenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.file-upload-containe{
    position: relative;
}

/* Media Queries for Responsiveness */
@media screen and (max-width: 800px) {
    .serviceRequest-container {
        width: 90%;
    }
    .service-para {
        width: 100%;
    }
    .service-form {
        width: 100%;
        margin-left: 0px;
    }
    .input-div {
        grid-template-columns: 30% 60%;
        column-gap: 15px;
    }
    .service-form-para2-text {
        font-size: 16px;
    }
    .service-form-label3 {
        font-size: 24px;
    }
    .file-upload-containe{
        position: relative;
    }
}

@media screen and (max-width: 550px) {
    .service-header {
        font-size: 26px;
        margin-left: 0px;
    }

    .service-form-para2-H {
        font-size: 22px;
    }
    .service-form {
        width: 100%;
        margin-left: 0px;
    }
    .input-div {
        grid-template-columns: auto;
        column-gap: 0px;
    }
    .service-form-label {
        text-align: left;
    }
    .service-para {
        font-size: 16px;
        margin-left: 0px;
    }
    .service-H_details {
        font-size: 16px;
    }
    .service-form-label {
        font-size: 16px;
        padding-bottom: 10px;
    }
    .service-form-label2 {
        font-size: 16px;
    }
    .service-form-label3 {
        font-size: 20px;
    }
    .PleaseDescribe {
        font-size: 16px;
    }
    .file-upload-containe{
        position: relative;
    }
}

div,h1,h2,h3,h4,h5,h6,p,b,strong,li{
    text-align: start !important;
}
