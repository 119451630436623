.outer {
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 0 40px #044dac10;
}

h2.h2 {
    font-size: calc(1.2rem + 1.5vw) !important;
}

@media screen and (max-width: 550px) {
    h2.h2 {
        font-size: calc(1.350rem + 1.5vw) !important;
    }
}