body{
    margin: 0;
    padding: 0;
    background-color: #eee;
}
*{
    box-sizing: border-box;
}
.blue {
    color: #044dac;
}

p,
li,
h1,h2,h3,h4,h5,h6,b{
    text-align: start !important;
}
