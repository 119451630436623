.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.8);
}

.loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.ScheduleShowing-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
}

.button-container {
    display: flex;
    justify-content: center;
    gap: 10px;
}


.ScheduleShowing-header {
    font-size: 34px;
    font-weight: 500 !important;
    text-align: start;
}

.ScheduleShowing-top-para {
    margin: 20px 0 !important;
    font-size: 18px;
    letter-spacing: 0.4px;
    word-spacing: 1px;
    width: 80%;
    text-align: left;
    font-weight: normal;
    line-height: 27px;
}

.ScheduleShowing-form {
    margin-top: 40px;
}

.ScheduleShowing-input {
    display: grid;
    grid-template-columns: 20% 75%;
    column-gap: 3%;
    width: 80%;
    margin-left: 5%;
    margin-top: 20px;
}

.ScheduleShowing-input label {
    text-align: right;
    font-size: 18px;
}
.ScheduleShowing-Pleaseprovide {
    width: 90%;
    font-size: 18px;
}

.rechaptcha-text {
    font-size: 18px;
    text-align: center;
    margin-top: 10px;
}

@media screen and (max-width: 800px) {
    .ScheduleShowing-buttons {
        flex-direction: column;
    }

    .button-container {
        flex-direction: column;
        gap: 10px;
    }
    .ScheduleShowing-top-para {
        width: 100%;
        font-size: 16px;
        text-align: left;
    }
    .ScheduleShowing-header {
        font-size: 26px;
        text-align: start;
    }
    .ScheduleShowing-Pleaseprovide {
        width: 100%;
        font-size: 15px;
    }
    .ScheduleShowing-input {
        column-gap: 3%;
        grid-template-columns: 23% 74%;
        width: 90%;
        margin-left: 15px;
    }
    .ScheduleShowing-input label {
        font-size: 16px;
    }
    .rechaptcha-text {
        font-size: 16px;
    }
}

@media screen and (max-width: 550px) {
    .ScheduleShowing-buttons {
        flex-direction: column;
    }

    .button-container {
        flex-direction: column;
        align-items: center;
    }
    .ScheduleShowing-top-para {
        width: 100%;
        text-align: left;
    }
    .ScheduleShowing-input {
        grid-template-columns: auto;
    }
    .ScheduleShowing-input label {
        text-align: left;
        margin-bottom: 10px;
    } 
}