
* {
    margin: 0;
    padding: 0;
}

.thankyou_container {
    width: 70%;
    margin: auto;
    margin-top: 50px;
    padding-top: 150px;
    padding-bottom: 40px;
}

.thankyou_heading {
    font-weight: 500;
    margin-bottom: 15px;
}

.recive_text {
    font-size: 20px;
    line-height: 30px;
    margin-top: 40px;
    text-align: start;
}

.thankyou_phoneE {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.thankyou_phoneE a {
    text-decoration: none;
    color: #007bff;
}

.thankyou_phoneE a:hover {
    text-decoration: underline;
    color: #007bff;
}

.thankyou_bgBox {
    background-color: #d1ecf1;
    color: #0c5460;
    border: 1px solid #bee5eb;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border-radius: .25rem;
    margin-top: 20px;
    text-align: start;
}