.required-number2 {
    border-radius: 5px;
    padding: 6px 10px !important;
    border: 1px solid #ccc !important;
    color: #555 !important;
}

.required-number:focus {
    border: 1px solid skyblue !important;
    outline: none !important;
    box-shadow: 0px 0px 6px 1px skyblue !important;
}

@media screen and (max-width: 550px) {
    .student-main-margin {
        padding: 0px 25px !important;
    }

    .propertiesMap {
        height: 400px;
    }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}