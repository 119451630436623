.blue {
    color: #044dac;
}

.prevBTN , .nextBTN{
    cursor: pointer;
}
.swiper-initialized .swiper-horizontal {
    padding: 20px !important;
}
.h2{
    font-size: 2.5rem !important;
}