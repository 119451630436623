.tenent-header {
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 25px;
}

.tenent-para {
    font-size: 18px;
    margin: 15px 0px 10px 15px;
}

.tenent-li-div {
    margin-left: 45px;
}

.tenent-li-div li {
    list-style-position: outside;
    padding-left: 10px;
    margin: 15px 0px;
    font-size: 18px;
    color: rgb(82, 82, 82);
    text-align: left;
}

.textLeft{
    text-align: left;
}
.tenent-table {
    width: 100%;
    padding: 20px 15px;
}

.tenent-table table {
    border-collapse: collapse;
    width: 100%;
}

.tenent-table table tr td {
    border: 1px solid rgb(145, 145, 145);
    padding: 7px 10px;
    text-align: left;
}

.tenent-table table tr:nth-child(even) {
    background-color: rgba(216, 216, 216, 0.4);
}

@media screen and (max-width: 550px) {
    .tenent-header {
        font-size: 24px;
    }
    .tenent-para {
        font-size: 16px;
        text-align: left;
    }
    .tenent-li-div {
        margin-left: 10px;
        text-align: left;
    }
    .tenent-table table tr td {
        font-size: 14px;
        text-align: left;
    }
}

.downloadbtn {
    border: none;
    background-color: rgb(194, 192, 192);
}