.SubletAgreementMainDiv {
    width: 100vw;
}

.SubletAgreementMain {
    width: 90%;
    margin: auto;
}


.SubletAgreementContent{
    overflow: hidden;
    text-align: justify;
}

.SubletAgreementPara1Input {
    border: none;
    outline: none;
    border-bottom: 1px solid;
    width: 368px;

}

.SubletAgreementPara2Input {
    border: none;
    outline: none;
    border-bottom: 1px solid;
    width: 100%;
}

.SubletAgreementPara3Input {
    border: none;
    outline: none;
    border-bottom: 1px solid;
    width: 100%;
}

.SubletAgreementPara4Input {
    border: none;
    outline: none;
    border-bottom: 1px solid;
    width: 175px;
}

.SubletAgreementPara5Input {
    border: none;
    outline: none;
    border-bottom: 1px solid;
    width: 50%;
}

.SubletAgreementPara6Input {
    border: none;
    outline: none;
    border-bottom: 1px solid;
    width: 50%;
}

.SubletAgreementPara7Input {
    border: none;
    outline: none;
    border-bottom: 1px solid;
    width: 550px;
}

.SubletAgreementPara8Input {
    border: none;
    outline: none;
    border-bottom: 1px solid;
}

.SubletAgreementPara10Input {
    border: none;
    outline: none;
    border-bottom: 1px solid;
    width: 397px;
}

.SubletAgreementPara11 {
    display: flex;
    width: 100%;
}

.SubletAgreementPara11Input {
    border: none;
    outline: none;
    border-bottom: 1px solid;
    width: 100%;
}

.SubletAgreementPara12Input {
    border: none;
    outline: none;
    border-bottom: 1px solid;
    width: 325px;
}

.SubletAgreementPara13Input {
    border: none;
    outline: none;
    border-bottom: 1px solid;
}

.SubletAgreementPara14Input {
    border: none;
    outline: none;
    border-bottom: 1px solid;
    width: 238px;
}

.SubletAgreementPara15Input {
    border: none;
    outline: none;
    border-bottom: 1px solid;
    width: 30%;
}

.SubletAgreementPara16Input {
    border: none;
    outline: none;
    border-bottom: 1px solid;
    width: 30%;
}

.SubletAgreementPara17Input {
    border: none;
    outline: none;
    border-bottom: 1px solid;
    width: 30%;
}

.SubletAgreementPara18Input {
    border: none;
    outline: none;
    border-bottom: 1px solid;
    width: 31%;
}

@media screen and (max-width:550px) {
    .SubletAgreementContent{
        text-align: inherit;
    }
    .SubletAgreementPara7Input{
        width: 100% !important;
    }
    .SubletAgreementPara10Input {
        width: 100%;
    }

    .SubletAgreementPara5Input {
        width: 100%;
    }

    .SubletAgreementPara6Input {
        width: 100%;
    }

    .SubletAgreementPara11{
        display: grid;
        grid-template-columns: 100%;
    }
    
    .SubletAgreementPara15Input {
        width: 100%;
    }

    .SubletAgreementPara16Input {
        width: 100%;
    }

    .SubletAgreementPara17Input {
        width: 100%;
    }

    .SubletAgreementPara18Input {
        width: 100%;
    }
}