.listings-container {
    background-color: #eee;
    position: relative;
    margin: 30px 50px;
    border-radius: 10px;
    box-shadow: 0 0 10px #00000030;
}

.browse-grid {
    display: grid;
    grid-template-columns: auto auto auto auto;
    justify-content: space-around;
    gap: 10px;
}

.browse-grid label.ms-2 label {
    margin-top: 10px;
}

.background-padding {
    padding: 25px;
}

.button-div {
    padding-top: 30px;
    float: right;
}

.sorting-div{
    padding-top: 30px;
    float: left;
}

.button-div button {
    padding: 4px 30px !important;
    float: inline-end;
    font-size: 16px;
}


.pagnation {
    border: 1px;
    justify-content: center;
    padding: 20px 15px;
}

.pagnation-button-div {
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
}

.first-button {
    position: absolute;
    left: 10%;
}


.first-button button {
    padding: 5px 15px !important;
}

.button-text {
    text-align: center;
    margin: auto;
    padding-top: 5px;
}

.secound-button {
    position: absolute;
    right: 10%;
}

.secound-button button {
    padding: 5px 15px !important;
}


.browse-linewave {
    position: absolute;
    width: 100% !important;
    top: 12%;
    left: 3%;
}

.mobileResponsive-text{
    padding: 0 50px;
}


@media screen and (max-width: 800px) {
    .browse-grid {
        grid-template-columns: auto auto;
    }
    .browse-linewave {
        top: 30%;
        left: 6%;
    }
}

@media screen and (max-width: 550px) {
    .mobileResponsive-text {
        display: flex;
        text-align: start;
        margin-top: 35px;
        padding: 0 20px;
        font-size: 20px;
    }

    .browse-grid {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: start;
        margin-top: 15px;
    }

    .browse-linewave {
        top: 45%;
        left: 10%;
    }

    .background-padding {
        padding: 15px;
    }

    .button-div {
        padding-top: 20px;
    }

    .background {
        margin-left: 15px !important;
        margin-right: 15px !important;
    }

    .listings-container {
        margin-left: 15px !important;
        margin-right: 15px !important;
    }

    .first-button {
        left: 10px;
    }
    .previous-button {
        font-size: 12px !important;
    }
    .next-button {
        font-size: 12px !important;
    }

    .secound-button {
        right: 10px;
    }

    .first-button button {
        padding: 5px 5px !important;
        font-size: 14px;
    }

    .secound-button button {
        padding: 5px 5px !important;
        font-size: 14px;
    }

    .button-text {
        font-size: 14px;
    }
}

@media screen and (max-width: 375px) {
    .browse-grid {
        grid-template-columns: auto;
    }
    .browse-linewave {
        top: 42%;
        left: 12%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .browse-grid {
        grid-template-columns: auto auto; /* Adjust to 2 columns for better alignment */
        gap: 15px; /* Increase spacing slightly */
    }

    .background-padding {
        padding: 20px; /* Ensure consistent padding */
    }

    .search-button {
        right: 20px; /* Adjust position for smaller width */
    }
}
