@media (max-width: 768px) {
    .input-group-text {
        font-size: 10px;
    }
}

@media (min-width: 769px) {
    .input-group-text {
        font-size: 1.2em;
    }
}

@media screen and (max-width: 550px) {
    .form-control {
        margin-top: 0px !important;
    }
}

.dash-board{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
}
