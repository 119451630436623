/* Styles for the number section container */
.number-section-container {
    background-color: #f8f9fa;
    padding: 50px 0;
    border-radius: 25px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

/* Styles for the rows */
.number-section-row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

/* Styles for the columns */
.number-section-col {
    text-align: center;
    margin-bottom: 20px;
}

/* Styles for the individual number components */
.number-component {
    font-size: 2.5rem;
    font-weight: bold;
    color: #044dac;
}

/* Add media query for mobile view */
@media screen and (max-width: 768px) {
    .number-section-container {
        margin-top: 05px; /* Add top margin for mobile view */
        width: 100%; /* Ensure the container width is 100% in mobile view */
    }
}