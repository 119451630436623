.blue {
    color: #044dac;
}

.left-align {
    text-align: left;
}

.left-align-container p {
    text-align: left;
  }