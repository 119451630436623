.card-container {
    /* min-height: 230px; */
    box-shadow: 0 0 40px #044dac2f;
    border-radius: 12px;
    /* height: 380px; */
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.card-paragraph  {
    display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
.expanded {
    display: block;
    height: unset !important;
  -webkit-line-clamp: unset !important;
  -webkit-box-orient: unset !important;  
  overflow: unset !important;
}

.icon-bg {
    width: fit-content;
    border-radius: 10px;
}

.h3 {
  font-size: 24px !important;
}

@media screen and (max-width:550px) {
  h3.h3 {
    font-size: 22px !important;
  }
}