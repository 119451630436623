.hero-container {
     background-color: #efefef;    
     padding: 20px; 
}

.hero-item {
    padding-top: 20px; /* Keep top padding */
    padding-right: 10px;
    padding-bottom: 0px; /* Reduce bottom padding */
    padding-left: 10px;
}

.hero-item img {
    width: 100%;
    height: auto;
    max-width: 100%;
    display: block;
    object-fit: cover; /* Ensures the image covers the container */
    object-position: left; /* Crops the image from the right */
}

.blue {
    color: #044dac;
}

/* Add media query for mobile view */
@media screen and (max-width: 768px) {
    .hero-item {

        padding-top: 10px; 
        padding-right: 10px;
        padding-bottom: 1px; 
        padding-left: 10px;
    }
    .hero-item img {
        max-width: 100%; /* Ensure the container width is 100% in mobile view */
    }
}