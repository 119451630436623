.confirm-property-main {
    width: 80%;
    height: auto;
    margin: auto;
    padding-bottom: 40px;
}

.confirm-property-head {
    font-size: 35px;
}

.confirm-property-para {
    width: 85%;
    margin-top: 40px;
    font-size: 18px;
    font-weight: 400;
}

.confirm-property-griddiv {
    margin-top: 30px;
    width: 85%;
    margin-left: 4%;
}

.confirm-property-grid {
    display: grid;
    grid-template-columns: 15% 80%;
    column-gap: 4%;
    padding: 10px 15px;
}
.ScheduleShowing-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
}

.confirm-property-LgridT {
    font-size: 20px;
    text-align: end;
}

.confirm-property-RgridT {
    font-size: 20px;
}

.confirm-property-RgridSubT {
    font-size: 20px;
}

.CPgridButton1 {
    font-size: 18px !important;
    padding: 5px 50px !important;
}

.CPgridButton2 {
    font-size: 18px !important;
    padding: 5px 45px !important;
}


.button-container {
    display: flex;
    justify-content: center;
    gap: 10px;
}

/* ======== confirm property media screen start ========*/

@media screen and (max-width: 800px) {
    .confirm-property-main {
        width: 90%;
    }
    .confirm-property-head {
        font-size: 26px;
    }
    .confirm-property-para {
        width: 95%;
        font-size: 16px;
    }
    .confirm-property-griddiv {
        width: 95%;
    }
    .confirm-property-grid {
        display: grid;
        grid-template-columns: 20% 74%;
        column-gap: 4%;
        padding: 10px 5px;
    }
    .confirm-property-LgridT {
        text-align: end;
        font-size: 18px;
    }
    .confirm-property-RgridT {
        font-size: 18px;
    }
    .confirm-property-RgridSubT {
        font-size: 18px;
    }
    .CPgridButton1 {
        font-size: 16px !important;
        padding: 4px 45px !important;
    }
    .CPgridButton2 {
        font-size: 16px !important;
        padding: 4px 40px !important;
    }
}

@media screen and (max-width: 550px) {
    .confirm-property-main {
        width: 100%;
        padding: 10px 10px 25px 10px;
    }
    .confirm-property-head {
        font-size: 16px;
        font-weight: bold;
    }
    .confirm-property-para {
        margin-top: 30px;
        font-size: 16px;
    }
    .confirm-property-griddiv {
        width: 100%;
        margin: 25px 0px 0px 0px;
        padding: 0px;
    }
    .confirm-property-grid {
        width: 100%;
        display: grid;
        grid-template-columns: 100%;
    }
    .confirm-property-LgridT {
        text-align: start;
        font-size: 16px;
    }
    .confirm-property-RgridT {
        font-size: 16px;
    }
    .confirm-property-RgridSubT {
        font-size: 16px;
    }
    .confirm-property-gridBTN {
        width: 100%;
        display: flex;
        border: 2px solid gray;
    }
    .CPgridButton1 {
        font-size: 16px !important;
        padding: 4px 45px !important;
    }
    .CPgridButton2 {
        font-size: 16px !important;
        padding: 4px 36px !important;
    }
    .form-control {
        margin-top: 10px !important;
    }
}