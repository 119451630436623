.footer {
    background: rgb(72, 120, 184);
    background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgba(4, 77, 172, 1) 100%);
    padding-bottom: 50px;
    padding-top: 100px;
    position: relative;
}

.footer-container {
    padding: 0px 10%;
}

.footer-row {
    display: grid;
    justify-content: space-between;
    grid-template-columns: auto auto;
}

.footer-right2 {
    display: flex;
    flex-direction: column;
    align-items: end;
}

.footer-img {
    width: 100%;
}

.footer-icon {
    margin: 0px 15px;
}

.dash {
    font-size: calc(1.3rem + 1.5vw) !important;
}

@media screen and (max-width: 800px) {
    .footer-row {
        grid-template-columns: 40% 60%;
    }
}

@media screen and (max-width: 550px) {
    .footer {
        padding: 50px 0px;
    }
    .footer-right2 {
        align-items: start;
    }
    .footer-row {
        grid-template-columns: auto;
    }
    .footer-right2 {
        margin-top: 30px;
    }
}